@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

@layer components {
  .card {
    @apply overflow-hidden rounded bg-white/80 p-4 shadow-md backdrop-blur-lg;
  }

  .card-hoverable {
    @apply transition-all hover:-translate-y-0.5 hover:bg-white/90 hover:shadow-lg;
  }
}

.bg-hero-polka-dots {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}

@keyframes move-up-and-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-left-and-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

.move-up-and-down {
  animation: move-up-and-down 3s ease-in-out infinite;
}

.move-left-and-right {
  animation: move-left-and-right 1s ease-in-out infinite;
}

.move-all {
  animation: move-up-and-down 1s ease-in-out infinite,
    move-left-and-right 2s ease-in-out infinite;
}

@keyframes move-diagonally {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(2rem, -2rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

.move-diagonally {
  animation: move-diagonally 7s ease-in-out infinite;
}